var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"12"}},[_c('VeeObserver',{ref:"form",attrs:{"slim":""}},[_c('VeeProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"E-mail","error":errors[0]},model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}}),_c('VeeProvider',{attrs:{"name":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select the roles"}},[_c('v-select',{attrs:{"multiple":"","reduce":function (ref) {
	var code = ref.code;

	return code;
},"options":_vm.selectRoles,"placeholder":"Select"},on:{"search":_vm.searchRoles},model:{value:(_vm.payload.roles),callback:function ($$v) {_vm.$set(_vm.payload, "roles", $$v)},expression:"payload.roles"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})]}}])}),_c('VeeProvider',{attrs:{"name":"image","rules":"length:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Photo"}},[_c('FileUpload',{ref:"image",model:{value:(_vm.payload.image),callback:function ($$v) {_vm.$set(_vm.payload, "image", $$v)},expression:"payload.image"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])}),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"my-4",attrs:{"variant":"primary","type":"submit","loading":_vm.loading},on:{"click":function($event){_vm.isEdit ? _vm.update() : _vm.create()}}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" "+_vm._s(_vm.isEdit ? "Update" : "Create")+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }