import Vue from "vue";

export default class RolesService {
  static baseUrl = "role";

  static searchRoles(search = "", onlyParent = false) {
    let uri = `${this.baseUrl}/search-select?only_parent=${onlyParent}`;

    if (search) {
      uri += `&search=${search}`;
    }

    return Vue.axios.get(uri);
  }
}
